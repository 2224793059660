import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "delete" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "delete__wrap" }, [
      _createElementVNode("div", { class: "delete__title" }, "Your account has been successfully removed")
    ], -1))
  ]))
}