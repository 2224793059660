
import Header from '@/components/sections/Header.vue'
export default {
  name: 'DeleteFinish',
  layout: 'delete',
  scrollToTop: true,
  data () {
    return {}
  },
  components: {
    Header
  },
  head () {
    return {
      title: 'Delete page',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'delete'
        }
      ]
    }
  },
  methods: {}
}
